import * as React from 'react'
import Layout from '../components/Layout'
import Testimonials from '../components/homepage/Testimonials'
import Seo from '../components/Seo'
import HaveQuestions from '../components/repairs/HaveQuestions'
import FAQComponent from '../components/layout/FAQComponent'

const FAQ = () => {
  return (
    <Layout className={'mx-0'} breadcrumbs={false} canonical="/faq/">
      <Seo
        title="Frequently Asked Questions | Gray and Sons Jewelers"
        noindex={false}
        description={
          'Frequently asked questions at Gray & Sons: quality, authenticity, orders, returns, shipping, and selling fine certified pre-owned watches and luxury jewelry.'
        }
        canonical="/faq/"
      />
      <h1 className="underline-title pt-5 text-xl md:text-2xl">FREQUENTLY ASKED QUESTIONS</h1>
      <FAQComponent />

      <div className="flex justify-center items-center  gap-20 flex-col w-full mb-10">
        <HaveQuestions />
        <Testimonials />
      </div>
    </Layout>
  )
}

export default FAQ
